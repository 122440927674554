import React from 'react';
import { Row, Col, Form, Radio, Divider, Input, InputNumber, Checkbox, Button, Spin } from 'antd';

export interface step2FormValue {
    height: number,
    weight: number,
    gender: string,
    age: number,
    email: string,
    contract: boolean,
}
interface Props {
    processing?: boolean;
    step2FromValue: step2FormValue;
    onPrev: () => void;
    onStep2?: (allValues: step2FormValue) => void;
}

const Step2Form: React.FC<Props> = (props) => {
    const { processing, step2FromValue, onPrev, onStep2 } = props;

    const config = {
        rules: [{ required: true, message: '此項目必填' }],
    };

    const handleSubmitStep2 = (values: { [name: string]: any }): void => {
        onStep2 && onStep2({
            height: values.height,
            weight: values.weight,
            gender: values.gender,
            age: values.age,
            email: values.email,
            contract: values.contract
        })
    };

    return (
        <Spin spinning={processing} tip="計算中...">
            <Form
                hideRequiredMark={true}
                initialValues={{
                    ...step2FromValue,
                    height: 160,
                    weight: 60
                }}
                onFinish={handleSubmitStep2}
            >
                <div className="form">
                    <h1 className="text-center">BMI身體質量指數計算器</h1>
                    <Divider />
                    <Row justify="center">
                        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={5}>
                            <Form.Item className="formItem" label="身高">
                                <Form.Item name="height" noStyle {...config}>
                                    <InputNumber className="input-noBorder" min={1} max={300} placeholder="身高(公分)" />
                                </Form.Item>
                                <span className="ant-form-text"> 公分</span>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={5}>
                            <Form.Item className="formItem" label="體重">
                                <Form.Item name="weight" noStyle {...config}>
                                    <InputNumber className="input-noBorder" min={1} max={300} placeholder="體重(公斤)" />
                                </Form.Item>
                                <span className="ant-form-text"> 公斤</span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={5}>
                            <Form.Item className="formItem" name="gender" label="性別" {...config}>
                                <Radio.Group>
                                    <Radio value="M">男</Radio>
                                    <Radio value="F">女</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={5}>
                            <Form.Item className="formItem" label="年齡">
                                <Form.Item name="age" noStyle {...config}>
                                    <InputNumber className="input-age" min={1} max={200} placeholder="年齡" />
                                </Form.Item>
                                <span className="ant-form-text"> 歲</span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
                            <Form.Item
                                className="formItem"
                                name="email"
                                label="電子郵件信箱"
                                rules={[
                                    {
                                        type: 'email',
                                        message: '請檢查Email格式',
                                    },
                                    {
                                        required: true,
                                        message: '填入電子信箱，可收到完整填答內容及問卷分析結果。',
                                    },
                                ]}
                            >
                                <Input className="input-email" placeholder="您的E-mail" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                            <Divider dashed />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <Form.Item
                                name="contract"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) => value ?
                                            Promise.resolve() : Promise.reject('若同意隱私權政策請勾選此欄位')
                                    },
                                ]}
                            >
                                <Checkbox>我已閱讀並同意 <a href="https://booking.resmed.ear.com.tw/privacy" target="_blank">隱私權政策</a></Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Row gutter={20} justify="center">
                    <Col>
                        <Form.Item>
                            <Button className="btn-master" onClick={() => onPrev()}>上一頁</Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button className="btn-master" htmlType="submit">完成</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default Step2Form;
