import React, { Fragment } from 'react';
import { Row, Col, Button, Form, Radio, Divider, Input, Checkbox } from 'antd';

interface Props {
    processing?: boolean;
    isHasRisk: boolean;
    bmiValue: number;
    onPrev: () => void;
    onDoingAgain: () => void;
}

const Result: React.FC<Props> = (props) => {
    const { processing, isHasRisk, bmiValue, onPrev,onDoingAgain } = props;
   
    return (
        <Fragment>
            <div className="form">
                <h1 className="text-center">線上睡眠問卷</h1>
                <p className="text-center result-text-sm">
                    本問卷能初步評估您是否有罹患阻塞型睡眠呼吸中止症的風險，以判斷出您是否需要作進一步的睡眠檢測。
                </p>
                <Divider />
                <div className="risk-result">
                    <h2 className="text-center">問卷結果</h2>
                    <p className="text-center result-text">
                        您的身體質量指數 (BMI) 
                        <span className={'bmiValue ' + `${isHasRisk ? 'risk-danger' : 'risk-safe'}`}> 
                            {bmiValue.toFixed(0)} 
                        </span>
                    </p>
                    <Row justify="center">
                        <Col span={13}>
                            <p className="result-info text-left">
                                { isHasRisk ?                                
                                    '您屬於阻塞型睡眠呼吸中止症的中高風險群，建議您做進一步的睡眠檢測。':
                                    '恭喜您！您目前為阻塞型睡眠呼吸中止症的低風險群。但阻塞型睡眠呼吸中止症與年齡增長有關，建議您可每半年進行一次線上睡眠檢測喔。' 
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={20} justify="center">
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            <Row justify="center">
                                <Col>
                                    <Button 
                                        ghost 
                                        className="resmed-link"
                                        href="https://booking.resmed.ear.com.tw/"
                                        target="_self"
                                    >
                                        立即預約居家睡眠檢測
                                    </Button>
                                    <Button 
                                        ghost 
                                        className="resmed-link"
                                        href="https://resmed.ear.com.tw/zh_TW/news/node/1504082337001"
                                        target="_self"
                                    >
                                        我想了解阻塞型睡眠呼吸中止症
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            <Row justify="center">
                                <a href="https://www.facebook.com/ResmedTW/" target="_blank">
                                    <Col span={24}>
                                        <img 
                                            src="/images/qrcode.png"  
                                            style={{
                                                width: '68px',
                                                margin: '10px auto',
                                                display: 'block',
                                            }}/>
                                    </Col>
                                    <Col span={24}>
                                        <p className="text-center" style={{color: '#dda800'}}>
                                            追蹤科林睡得美 
                                        </p>
                                    </Col>
                                </a>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                        <Divider dashed />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <p className="text-center">以上結果只供參考，並不能代替醫生的臨床意見、診斷或治療。如您對自己的健康有任何疑問，請向您的醫生查詢。</p>
                    </Col>
                </Row>
            </div>
            <Row gutter={20} justify="center">
                <Col> 
                    <Button className="btn-master" onClick={() => onPrev()}>上一頁</Button>                  
                </Col>
                <Col>            
                    <Button 
                        className="btn-again" onClick={() => onDoingAgain()}
                    >
                        再測一次
                    </Button>                
                </Col>
                <Col>            
                    <Button 
                        className="btn-back" 
                        href="https://resmed.ear.com.tw/"
                        target="_blank"
                    >
                        回官網首頁
                    </Button>                
                </Col>
            </Row>
        </Fragment>
    );
};

export default Result;
