import React, { useEffect, useState } from 'react';
import { Layout, Button, Row } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from '../containers/base.container';
import { ResmedHeader, ResmedFooter } from '@clinico/clinico-components';
const { Content } = Layout;

interface Props extends WithBaseContainerProps, RouteComponentProps {}

const MainLayout: React.FC<Props> = (props) => {
    return (
        <Layout className="main-layout">
            <ResmedHeader
                logButton={
                    <Button size="large" className="btn-login" href="https://member.resmed.ear.com.tw/login">
                        會員登入
                    </Button>
                }
            />
            <Content className="content">{props.children}</Content>
            <ResmedFooter />
        </Layout>
    );
};

export default withBaseContainer(withRouter(MainLayout));
