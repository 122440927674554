import React from 'react';
import { Row, Col, Button, Form, Radio, Divider } from 'antd';

export interface step1FormValue {
    q1: number,
    q2: number,
    q3: number,
    q4: number,
    q5: number,
}

interface Props {
    step1FromValue: step1FormValue;
    processing?: boolean;
    onStep1?: (allValues: step1FormValue) => void;
}

const Step1Form: React.FC<Props> = (props) => {
    const { processing, step1FromValue, onStep1 } = props;

    const config = {
        className: 'formItem',
        rules: [{ required: true, message: '此項目必填' }],
    };

    const handleSubmitStep1 = (values: { [name: string]: any }):void => {
        onStep1 && onStep1({
            q1: values.q1, 
            q2: values.q2, 
            q3: values.q3,
            q4: values.q4,
            q5: values.q5
        })
    }

    return (
        <Form 
            layout="vertical" 
            initialValues={{...step1FromValue}}
            hideRequiredMark={true} 
            onFinish={handleSubmitStep1}>
            <div className ="form">
                <h1 className="text-center">線上睡眠問卷</h1>
                <p className="text-center">本問卷能初步評估您是否有罹患阻塞型睡眠呼吸中止症的風險，以判斷出您是否需要作進一步的睡眠檢測。</p>
                <Divider />
                <Form.Item name="q1" label="您是否鼾聲過大? 大於說話音量或從門外即可聽見?" {...config}>
                    <Radio.Group>
                        <Radio value='Y'>是</Radio>
                        <Radio value='N'>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="q2" label="您是否白天經常感到疲憊或想睡? 即使前一晚睡得很好?" {...config}>
                    <Radio.Group>
                        <Radio value='Y'>是</Radio>
                        <Radio value='N'>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="q3" label="是否有人發現您睡覺時呼吸中止、嗆到或喘氣?" {...config}>
                    <Radio.Group>
                        <Radio value='Y'>是</Radio>
                        <Radio value='N'>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="q4" label="您是否罹患高血壓或正接受治療?" {...config}>
                    <Radio.Group>
                        <Radio value='Y'>是</Radio>
                        <Radio value='N'>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="q5" label="您的脖圍是否超過40公分（15.5吋）" {...config}>
                    <Radio.Group>
                        <Radio value='Y'>是</Radio>
                        <Radio value='N'>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                        <Divider dashed />
                        <p className="text-center">本問卷修改自Chung F et al. Anesthesiology 2008; 108(5):812-821.</p>
                    </Col>
                </Row>
            </div>
            <Row gutter={20} justify="center"> 
                <Col>
                    <Form.Item>
                        <Button className="btn-master" htmlType="submit">下一頁</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default Step1Form;
