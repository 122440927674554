import React, { useState } from 'react';
import { Modal } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from './base.container';
import Components from '../components';
import { QuestionnairesService } from '../services/quesitonnaires.service';

interface Props extends RouteComponentProps<{ memberId: string }>, WithBaseContainerProps {}

const MemberDetailContainer: React.FC<Props> = (props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [current, setCurrent] = useState(0);
    const [step1FromValue, setStep1FromValue] = useState<any>({});
    const [step2FromValue, setStep2FromValue] = useState<any>({});
    const [bmiValue, setBmiValue] = useState<number>(0);
    const [isHasRisk, setIsHasRisk] = useState<boolean>(false);

    const next = () => {
        setCurrent(current + 1)
    }

    const prev = () => {
        setCurrent(current - 1)
    }
    const doingAgain=()=>{
        setCurrent(0)
        setStep1FromValue({})
        setStep2FromValue({})
        setBmiValue(0)
        setIsHasRisk(false)
    }

    const handleStep1FormValue = (step1Values: any) => {
        if (step1Values) {
            setStep1FromValue(step1Values);
            next();
        }   
    }

    const handleStep2FormValue = (step2Values: any) => {
        if (step2Values) {
            setStep2FromValue(step2Values);

            // BMI = 體重（公斤）除以身高（公分換算成公尺）的平方
            const height = step2Values.height / 100;
            const bmi = step2Values.weight / (height * height);
            setBmiValue(bmi);
            countRisk(step1FromValue, step2Values, bmi, step2Values.gender);
            props.snedGoogleEventForCompleted();
        }
    }
    
    const countRisk = async (step1Values: any, step2Values: any, bmi: number, gender: string) => {
        const stpe1ValueArray = Object.values(step1Values);
        const step1Count = stpe1ValueArray.filter((item) => { return item === 'Y'});
        if (
            step1Count.length > 2 || 
            step1Count.length > 1 && bmi > 35 ||
            step1Count.length > 1 && gender === 'M' ||
            step1Count.length > 0 && gender === 'M' && bmi > 35
            ) {
                setIsHasRisk(true);
        }   
    
        try {
            setProcessing(true);
            step2Values['questionnaire'] = step1Values;
            await QuestionnairesService.questionnaires(step2Values);
        } catch(err) {
            Modal.error(err);
        } finally {
            setProcessing(false);
            next();
        }
    }

    const steps = [
        {
          title: '1',
          content: (
            <Components.form.Step1  
                step1FromValue={step1FromValue}
                onStep1={handleStep1FormValue}
            />
          ),
        },
        {
          title: '2',
          content: (
            <Components.form.Step2 
                processing={processing}
                step2FromValue={step2FromValue}
                onPrev={prev}
                onStep2={handleStep2FormValue}
            />
          )
        },
        {
          title: '3',
          content: (
            <Components.form.Result 
                processing={processing}
                isHasRisk={isHasRisk}
                bmiValue={bmiValue}
                onPrev={prev}
                onDoingAgain={doingAgain}
            />
          )
        },
    ];

    return (
        <div className="questionnaire-container">
            <div className="steps-content">{steps[current].content}</div>
        </div>
    );
};

export default withBaseContainer(withRouter(MemberDetailContainer));
