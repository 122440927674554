import { QuestionnairesAPI } from '../api/questionnaires.api';

export class QuestionnairesService {
    static async questionnaires(data: {
        email: string;
        gender: 'M' | 'F';
        age: number;
        height: number;
        weight: number;
        questionnaire: { 
            [name: string]: {
                title: string;
                value: string;
            } | string;
        }
    }) {
        let res = await QuestionnairesAPI.questionnaires(data);

        if (!res.success) {
            throw res.error;
        }
        return res.result?.data || undefined;
    }
}