import axios, { AxiosRequestConfig } from 'axios';
import configs from '../configs';

export interface APIRequest {
    path: string;
    method: 'GET' | 'POST';
    params?: AxiosRequestConfig['params'];
    data?: AxiosRequestConfig['data'];
}

export interface APIResponseResult<T> {
    data: T;
}

export interface APIResponseError {
    status: number;
    substatus: number;
    type: string;
    code: string;
    message: string;
}

export interface APIResponse<T> {
    success: boolean;
    result?: APIResponseResult<T>;
    error?: APIResponseError;
}

export class BaseAPI {
    static async call<T = any>(request: APIRequest): Promise<APIResponse<T>> {
        try {
            let response = await axios({
                baseURL: configs.apiHost,
                url: request.path,
                method: request.method,
                params: request.params,
                data: request.data,
            });

            return response.data;
        } catch (error) {
            if (typeof error.response.data === 'string') {
                return { success: false, error };
            }
            return error.response.data;
        }
    }
}