import React from 'react';
import { Modal, notification as Notification, message as Message } from 'antd';

export interface WithBaseContainerProps {
    showConfirm: (message: React.ReactNode, title?: boolean) => Promise<boolean>;
    showWarningNotification: (message: React.ReactNode) => void;
    showErrorModal: (error: Error | string, type?: string) => void;
    showSuccessMessage: (message: React.ReactNode) => void;
    snedGoogleEventForCompleted: () => void;
}

export const withBaseContainer = <T extends WithBaseContainerProps>(WrappedComponent: React.ComponentType<T>) => {
    class WithBaseContainer extends React.Component<any> {
        showConfirm = async (message: React.ReactNode, title = '確認'): Promise<boolean> => {
            return new Promise((resolve, reject) => {
                Modal.confirm({
                    title,
                    content: message,
                    okText: '確定',
                    cancelText: '取消',
                    onOk: () => {
                        resolve(true);
                    },
                    onCancel: () => {
                        resolve(false);
                    },
                });
            });
        };

        showWarningNotification = (message: React.ReactNode): void => {
            Notification.warning({
                message: '警告',
                description: message,
            });
        };

        showErrorModal = (error: Error | string, type?: string): void => {
            let message = '';
            if (typeof error === 'string') {
                message = error;
            }
            if (typeof error === 'object' && !Array.isArray(error)) {
                message = error ? error.message : '';
            }
            if (type === "AuthenticationFailedError") {
                Modal.error({ title: '錯誤', content: message, okText: '確定', onOk: () => { this.props.history.push('/login') }});
            } else {
                Modal.error({ title: '錯誤', content: message, okText: '確定'});
            }
            console.error(error);
        };

        showSuccessMessage = (message: React.ReactNode): void => {
            Message.success(message);
        };

        snedGoogleEventForCompleted() {
            // @ts-ignore
            if (window.gtag) {
                // @ts-ignore
                window.gtag('event', 'Questionnaire', {
                    'event_category': 'HST',
                    'event_label': 'stop-bang_ok',
                    'value': 1,
                });
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...(this.props as T)}
                    showConfirm={this.showConfirm}
                    showWarningNotification={this.showWarningNotification}
                    showErrorModal={this.showErrorModal}
                    showSuccessMessage={this.showSuccessMessage}
                    moment
                />
            );
        }
    }

    return WithBaseContainer;
};
