import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Layouts from './layouts';
import Containers from './containers';
import './styles/style.scss';
import zhTW from 'antd/es/locale/zh_TW';

const Questionnaire: FunctionComponent<RouteComponentProps> = ({ match }) => (
    <Switch>
        <Route path={`${match.url}`} component={Containers.Questionnaire} />
    </Switch>  
);



ReactDOM.render(
    <ConfigProvider locale={zhTW}>
        <Router>
            <Layouts.Main>
                <Route path="/" component={Questionnaire} />
            </Layouts.Main>
        </Router>
    </ConfigProvider>,
    document.getElementById('root')
);
