import { BaseAPI, APIResponse } from './base.api';
import { QuestionnairesDto } from '../transformers/questionnaires.dto';

export class QuestionnairesAPI extends BaseAPI {
    static async questionnaires(data: {
        email: string;
        gender: 'M' | 'F';
        age: number;
        height: number;
        weight: number;
        questionnaire: { 
            [name: string]: {
                title: string;
                value: string;
            } | string;
        };
    }): Promise<APIResponse<QuestionnairesDto>> {
        let result = await BaseAPI.call<QuestionnairesDto>({
            path: `/shared/questionnaires`,
            method: 'POST',
            data,
        });
        return result;
    }
}